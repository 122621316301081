import { FCC } from '~/core/@types/global'
import LPContainer from '../../LPContainer'
import { t } from 'i18next'
import { TypographyH2 } from '~/core/ui/Heading'
import DotBadge from '../DotBadge'

const SectionContainer: FCC<{
  className?: string
  tagColor?: string
  tagLabel: string
  title: string
  description: string
  descriptionClassName?: string
}> = ({
  children,
  className,
  tagColor = 'text-purple-500',
  title,
  tagLabel,
  description,
  descriptionClassName
}) => {
  return (
    <div
      className={`overflow-hidden my-[80px] desktop:my-[160px] ${className}`}>
      <LPContainer>
        <div className="flex-1 flex flex-col justify-center max-w-[343px] tablet:max-w-3xl mx-auto items-center text-center">
          <DotBadge label={t(tagLabel)} dotClassName={tagColor} />

          <TypographyH2 className="mt-3 mb-5 text-center text-4xl tablet:text-4.5xl font-normal">
            {t(title)}
          </TypographyH2>
          <div className={`text-[19px] lead-[28px] max-w-[638px]`}>
            {t(description)}
          </div>
        </div>
        {children}
      </LPContainer>
    </div>
  )
}

export default SectionContainer
